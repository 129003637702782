import React from 'react';
import {Col, Row} from 'react-bootstrap';

import Layout from '../../components/layout/layout';
import styles from './about.module.scss';

const About = ({location}) => {
  return (
    <Layout location={location}>
      <Row className={styles.about}>
        <Col xs={12} md={6} xl={6} className={styles.about__info}>
          <h1>About</h1>
          <p>
            CELLR was born from the great dinners and wines shared by a small
            group of friends. A group of friends who would collectively open
            their cellars to share offerings and vintages that could no longer
            be easily found. Vintages, that if found, were subjected to the more
            formal and pricey auction or broker process. We wanted simplicity.
            We wanted to increase our circle of friends who also had an
            appreciation for great wines. We wanted an app that was easy to use,
            search, and gave us recommendations based on what we were actually
            drinking and not what was being sold on closeout by one of the many
            apps. We wanted efficiency, trade, and ease of use…and thus, we
            founded CELLR for on-demand cellar access.
          </p>
        </Col>
        <Col xs={12} md={6} xl={6}>
          <Row className={styles.about__group}>
            <Col xs={12} md={12} lg={6}>
              <h3>10k+</h3>
              <p>Producers</p>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h3>28</h3>
              <p>Countries</p>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h3>100+</h3>
              <p>Subregions</p>
            </Col>
            <Col xs={12} md={12} lg={6}>
              <h3>Beta</h3>
              <p>Users</p>
            </Col>
          </Row>
        </Col>
      </Row>
    </Layout>
  );
};

export default About;
